*,
*::before,
*::after {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Myriad Pro';
    // src: url(/assets/fonts/myriad-pro-cufonfonts//MYRIADPRO-REGULAR.OTF);
    src: url(./assets/fonts/myriad-pro-cufonfonts/MYRIADPRO-REGULAR.OTF);
}

body {
    font-family: 'Myriad Pro';
    font-size: 14px;
    color: #000;
    line-height: 1.7em;
    font-weight: 400;
    background: #000;
    position: relative;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}

a {
    text-decoration: none;
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-weight: normal;
    margin: 0px;
    background: none;
    line-height: 1.6em;
}

h1 {
    font-size: 4em;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 34px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

p {
    position: relative;
    line-height: 1.8em;
}

.auto_container {
    position: relative;
    max-width: 1150px;
    padding: 0px 60px;
    margin: 0 auto;
}


// .CustomContainer {
//     position: relative;
//     padding: 0px 150px;
// }
// @media only screen and (min-width: 768px) and (max-width: 1024px) {
//     .CustomContainer {
//         position: relative;
//         padding: 0px 50px;
//     }
// }

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: gray;
    border: 1px solid gray;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}


.py-60 {
    padding: 20px 0px;
}

.MainBG {
    display: flex;
    align-items: center;
}

.RewardsPoints {
    text-align: center;
}

.RewardsPoints h2 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
}

.background_image_point {
    background-image: url(assets/images/Side_Number_Panels_STATIC.gif);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px;
}

.AmountPoints {
    background: #fff;

    display: inline-block;
    padding: 5px 30px;
    border-radius: 15px;
    width: 70%;
    box-shadow: inset 0px 0px 20px 0px #4c4c4c;
}

.MainDesktop {
    padding: 15px;
    // --angle: 0deg;
    // border: 3px solid;
    // border-image: conic-gradient(from var(--angle), yellow 20%, black 40%, hotpink 50%, red 55%, white 70%, black 100%) 1;
    // animation: 3s rotate linear infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

.Heads {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.Heads h2 {
    text-shadow: 0 0 5px hotpink;
    color: hotpink;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 40px;
    animation: textshaow 5s infinite;
}

@keyframes textshaow {
    50% {
        text-shadow: 0px 0px 10px hotpink;
    }
}

.Heads h1 {
    color: #fff;
    font-weight: bolder;
    font-size: 45px;
}

.ScoreTable {
    overflow-x: hidden;
}

.MobileScreen {
    max-width: 390px;
    margin: auto;
    position: relative;
    // margin-left: 20px;
}

.BottomBar {
    height: 50px;
    background: #002D62;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
}

.BottomIcons img {
    width: 30px;
}

.CenterIcon img {
    width: 60px;
    position: relative;
    bottom: 20px;
}

.PointBlance {
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    // background: #fff;
    display: inline-block;
    width: fit-content;
    // box-shadow: inset -1px -5px 20px 7px #7a7a7a;
    border-radius: 30px;
    padding: 2px 20px;
    font-size: 16px;
    background: -webkit-linear-gradient(#ffffff, #d4cfcf);
}

.PointBlance2 {
    position: absolute;
    bottom: 97px;
    right: 59px;
    text-align: center;
    display: inline-block;

}


.leaders {
    position: absolute;
    bottom: 131px;
    left: 17px;
    width: -moz-fit-content;
    background: -webkit-linear-gradient(#fbfbf5, #EEBF1F);
    padding: 2px 7px;
    font-size: 14px;
    font-weight: 900;
    border-radius: 5px;
}

.rewards {
    position: absolute;
    bottom: 131px;
    right: 17px;
    width: -moz-fit-content;
    background: -webkit-linear-gradient(#fbfbf5, #EEBF1F);
    padding: 2px 7px;
    font-size: 14px;
    font-weight: 900;
    border-radius: 5px;
}

.LogoTop img {
    width: 80%;
    margin: auto;
    display: block;
}

.LogoTop {
    position: absolute;
    top: 0;
    padding: 15px;
}

.swiper {
    height: 390px;
    position: absolute;
    bottom: 130px;
    width: 200px;
    margin: auto;
    left: 0;
    right: 0;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* height: auto!important; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-next::after {
    display: none;
}

.swiper-wrapper {
    position: relative;
}

.swiper-button-next img {
    width: 80px;
}

.swiper-button-next {
    right: 40px;
}

.swiper_spinner_heading {
    font-weight: 900;
    font-family: sans-serif;
    text-transform: uppercase;
}

.InnerContanet {
    background: #ccc;
    border-bottom: 2px solid #000;
    width: 100%;
    padding: 8px 5px 8px 5px;
    background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
}

.InnerContanet h4 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    font-family: sans-serif;
}

.InnerContanet h5 {
    font-size: 16px;
    font-weight: 500;
    font-family: sans-serif;
}

.InnerContanet h6 {
    font-size: 14px;
    font-family: sans-serif;
    color: #2525b1;
}

.LogoRight img {
    width: 70%;
}

.BottomBorder img {
    width: 100%;
}

.TopBorder img {
    width: 100%;
}

.BottomBorder {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
}

.TopBorder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
}

.swiper {
    --angle: 0deg;
    // border: 3px solid;
    // border-image: conic-gradient(from var(--angle), blue 20%, black 40%, hotpink 50%, red 55%, white 70%, blue 100%) 1;
    // animation: 3s SwiperBorder linear infinite;
}

@keyframes SwiperBorder {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

.bussinessinfo {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9999;
    top: 0;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TopItitle h3 {
    text-transform: uppercase;
    color: #002D62;
    font-weight: 600;
    font-size: 25px;
    line-height: inherit;
}

.TopItitle p {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin: 0;
}

.RewD h3 {
    line-height: 36px;
    font-size: 26px;
    font-weight: 600;
}

.Claim p {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin: 0;
    line-height: initial;
    margin-bottom: 10px;
}

.GroupBtn button {
    background: #EEBD31;
    padding: 4px 16px;
    border-radius: 10px;
    font-weight: 500;
    min-width: 60px;
    font-size: 16px;
}

.InnerInfo {
    flex-direction: column;
    display: flex;
    gap: 10px;
    width: 90%;
    text-align: center;
    padding: 10px;
    background: #ddd;
    margin: auto;
    box-shadow: 0 0 15px blue;
    position: relative;
}

.bussinessinfo::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: #0000009c;
    height: 100%;
    z-index: -1;
}

.Claim {
    position: relative;
    z-index: 999;
}

.RewD {
    position: relative;
    z-index: 999;
}

.InnerInfo:before {
    content: '';
    position: absolute;
    top: 0;
    height: 70px;
    background: linear-gradient(359deg, rgb(226 226 226) 0%, rgb(139 137 137) 80%);
    width: 100%;
    left: 0;
    right: 0;
}

.InnerInfo:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 70px;
    background: linear-gradient(180deg, rgb(226 226 226) 0%, rgb(139 137 137) 80%);
    width: 100%;
    left: 0;
    right: 0;
}




.MobileScreenNew {
    max-width: 273px;
    margin: auto;
    position: relative;
    // margin-left: 20px;

}


.InnerContanetNew {
    background: #ccc;
    border-bottom: 2px solid #000;
    width: 100%;
    // padding: 0px 5px 0px 5px;
    padding: 8px 1px 1px 8px;
    background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
}

.InnerContanetNew h4 {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    font-family: sans-serif;
}

.InnerContanetNew h5 {
    font-size: 16px;
    font-weight: 500;
    font-family: sans-serif;
}

.InnerContanetNew h6 {
    font-size: 14px;
    font-family: sans-serif;
    color: #2525b1;
}

.leadersNew {
    position: absolute;
    bottom: 131px;
    left: 5px;
    width: -moz-fit-content;
    background: -webkit-linear-gradient(#fbfbf5, #EEBF1F);
    padding: 2px 7px;
    font-size: 9px;
    font-weight: 900;
    border-radius: 5px;
}

.rewardsNew {
    position: absolute;
    bottom: 131px;
    right: 5px;
    width: -moz-fit-content;
    background: -webkit-linear-gradient(#fbfbf5, #EEBF1F);
    padding: 2px 7px;
    font-size: 9px;
    font-weight: 900;
    border-radius: 5px;
}


.PointBlanceNew2 {
    position: absolute;
    bottom: 110px;
    right: 34px;
    text-align: center;
    display: inline-block;

}

.PointBlance3 {
    position: absolute;
    bottom: -120px;
    right: -97px;
    text-align: center;
    display: inline-block;
}

.mobileScreeMargin {
    margin-top: 5%;
    margin-left: 23%;
}

.phoneHand {
    width: 450px;
    height: 590px;
}

.swiper-new {
    height: 255px;
    width: 150px;
}

.outer_img {
    width: 204px;
    height: 296px;
}

.swiper-button-position{
    right: 25px;
    top: 202px;
}
.spin-next-button-img{
    width: 50px;
}


@media only screen and (min-width: 320px) and (max-width:600px) {

    .GroupBtn button {
        background: #EEBD31;
        padding: 3px 14px;
        border-radius: 10px;
        font-weight: 500;
        min-width: 60px;
        font-size: 12px;
        margin-top: 10px!important;
    }

    .Claim p {
        font-size: 12px!important;
        font-weight: 500;
        color: #000;
        margin: 0;
        line-height: initial;
        margin-bottom: 10px;
    }

    .TopItitle h3 {
        text-transform: uppercase;
        color: #002D62;
        font-weight: 600;
        font-size: 16px!important;
        line-height: inherit;
    }

    .RewD h3 {
        line-height: 36px;
        font-size: 16px!important;
        font-weight: 600;
    }
    .mobileScreeMargin {
        margin-top: 25% !important;
        margin-left: 46% !important;
    }

    .MobileScreenNew {
        max-width: 166px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 315px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -95px!important;
        right: -60px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 276px!important;
        height: 468px!important;
    }

    .swiper-new {
        height: 189px !important;
        width: 110px !important;
        bottom: 123px!important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 8px!important;
        top: 149px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 6px 5px 6px 5px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 11px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 8px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 151px!important;
        height: 221px!important;
    }

    .pointBalanceNew_div{
        font-size: 11px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 12px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 107px!important;
        right: 8px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 0px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width:1100px) {
    .mobileScreeMargin {
        margin-top: 25% !important;
        margin-left: 14% !important;
    }

    .MobileScreenNew {
        max-width: 166px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 315px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -95px!important;
        right: -60px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 276px!important;
        height: 468px!important;
    }

    .swiper-new {
        height: 189px !important;
        width: 110px !important;
        bottom: 123px!important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 8px!important;
        top: 149px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 6px 5px 6px 5px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 11px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 8px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 151px!important;
        height: 221px!important;
    }

    .pointBalanceNew_div{
        font-size: 11px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 12px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 107px!important;
        right: 8px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}


@media only screen and (min-width: 1100px) and (max-width:1150px) {
    .mobileScreeMargin {
        margin-top: 25% !important;
        margin-left: 14% !important;
    }

    .MobileScreenNew {
        max-width: 166px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 315px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -95px!important;
        right: -60px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 276px!important;
        height: 468px!important;
    }

    .swiper-new {
        height: 189px !important;
        width: 110px !important;
        bottom: 123px!important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 8px!important;
        top: 149px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 6px 5px 6px 5px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 11px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 8px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 151px!important;
        height: 221px!important;
    }

    .pointBalanceNew_div{
        font-size: 11px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 12px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 107px!important;
        right: 8px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}

@media only screen and (min-width: 1150px) and (max-width:1200px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 172px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 315px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -95px!important;
        right: -62px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 287px!important;
        height: 468px!important;
    }

    .swiper-new {
        height: 189px !important;
        width: 110px !important;
        bottom: 123px!important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 8px!important;
        top: 149px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 6px 5px 6px 5px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 11px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 8px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 151px!important;
        height: 221px!important;
    }

    .pointBalanceNew_div{
        font-size: 11px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 12px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 107px!important;
        right: 10px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1250px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 180px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 330px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -104px!important;
        right: -65px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 301px!important;
        height: 492px!important;
    }

    .swiper-new {
        height: 195px !important;
        width: 120px !important;
        bottom: 124px!important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 12px!important;
        top: 171px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 7px 7px 8px 7px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 11px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 8px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 163px!important;
        height: 228px!important;
    }

    .pointBalanceNew_div{
        font-size: 13px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 15px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 108px!important;
        right: 8px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}

@media only screen and (min-width: 1250px) and (max-width:1300px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 189px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 345px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -111px!important;
        right: -67px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 315px!important;
        height: 514px!important;
    }

    .swiper-new {
        height: 210px !important;
        width: 125px !important;
        bottom: 123px!important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 12px!important;
        top: 171px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 9px 7px 8px 7px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 11px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 10px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 169px!important;
        height: 246px!important;
    }

    .pointBalanceNew_div{
        font-size: 13px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 15px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 106px!important;
        right: 10px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}


@media only screen and (min-width: 1300px) and (max-width:1350px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 195px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 360px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -113px!important;
        right: -71px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 326px!important;
        height: 532px!important;
    }

    .swiper-new {
        height: 220px !important;
        width: 130px !important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 12px!important;
        top: 171px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 8px 7px 8px 7px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 12px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 10px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 176px!important;
        height: 258px!important;
    }

    .pointBalanceNew_div{
        font-size: 13px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 15px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 111px!important;
        right: 11px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}

@media only screen and (min-width: 1350px) and (max-width:1400px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 200px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 360px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -113px!important;
        right: -72px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 334px!important;
        height: 532px!important;
    }

    .swiper-new {
        height: 220px !important;
        width: 130px !important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 12px!important;
        top: 171px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 8px 7px 8px 7px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 12px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 10px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 178px!important;
        height: 258px!important;
    }

    .pointBalanceNew_div{
        font-size: 13px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 15px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 113px!important;
        right: 11px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}

@media only screen and (min-width: 1400px) and (max-width:1500px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 210px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 360px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -113px!important;
        right: -74px!important;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 349px!important;
        height: 532px!important;
    }

    .swiper-new {
        height: 220px !important;
        width: 140px !important;
    }
    .swiper-button-next img {
        width: 36px!important;
    }

    .swiper-button-position{
        right: 12px!important;
        top: 171px!important;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 8px 7px 8px 7px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 12px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 10px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 190px!important;
        height: 258px!important;
    }

    .pointBalanceNew_div{
        font-size: 13px!important;
    }
    .pointBalanceNew_txt{
        font-family: 'Myriad Pro';
        color: #002D62;
        font-size: 15px!important;
        font-weight: 9
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 111px!important;
        right: 11px!important;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        // padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        // padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        // padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        // padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 50px!important;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 225px !important;
    }
}


@media only screen and (min-width: 1500px) and (max-width:1600px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 229px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 410px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -120px;
        right: -82px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 382px;
        height: 590px;
    }

    .swiper-new {
        height: 260px !important;
        width: 160px !important;
    }
    .swiper-button-next img {
        width: 47px;
    }

    .swiper-button-position{
        right: 12px;
        top: 203px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 11px 8px 11px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 14px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 12px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 218px;
        height: 303px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 108px;
        right: 6px;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 289px !important;
    }
}

@media only screen and (min-width: 1600px) and (max-width:1700px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 260px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 460px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -139px;
        right: -91px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 430px;
        height: 660px;
    }

    .swiper-new {
        height: 306px !important;
        width: 170px !important;
    }
    .swiper-button-next img {
        width: 47px;
    }

    .swiper-button-position{
        right: 20px;
        top: 228px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 16px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 14px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 231px;
        height: 357px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 105px;
        right: 14px;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 289px !important;
    }
}

@media only screen and (min-width: 1700px) and (max-width:1800px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 260px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 460px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -139px;
        right: -91px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 430px;
        height: 660px;
    }

    .swiper-new {
        height: 306px !important;
        width: 170px !important;
    }
    .swiper-button-next img {
        width: 47px;
    }

    .swiper-button-position{
        right: 20px;
        top: 228px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 16px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 14px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 231px;
        height: 357px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 105px;
        right: 14px;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 1%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 1%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 1%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 1%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 289px !important;
    }
}

@media only screen and (min-width: 1800px) and (max-width:1900px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 290px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 500px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -148px;
        right: -104px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 481px;
        height: 710px;
    }

    .swiper-new {
        height: 335px !important;
        width: 180px !important;
    }
    .swiper-button-next img {
        width: 57px;
    }

    .swiper-button-position{
        right: 20px;
        top: 247px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 20px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 18px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 244px;
        height: 390px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 103px;
        right: 23px;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 2%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 2%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 2%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 2%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 355px !important;
    }
}

@media only screen and (min-width: 1900px) and (max-width:2000px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 290px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 500px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -148px;
        right: -104px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 481px;
        height: 710px;
    }

    .swiper-new {
        height: 335px !important;
        width: 180px !important;
    }
    .swiper-button-next img {
        width: 57px;
    }

    .swiper-button-position{
        right: 20px;
        top: 247px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 3px solid #000;
        width: 100%;
        padding: 14px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 18px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 16px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 244px;
        height: 390px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 103px;
        right: 23px;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 2%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 2%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 2%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 2%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 355px !important;
    }
}

@media only screen and (min-width: 2000px) and (max-width:2100px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 315px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 545px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -168px;
        right: -109px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 515px;
        height: 780px;
    }

    .swiper-new {
        height: 361px !important;
        width: 190px !important;
    }
    .swiper-button-next img {
        width: 57px;
    }

    .swiper-button-position{
        right: 23px;
        top: 286px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 20px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 18px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 262px;
        height: 419px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 101px;
        right: 27px;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 2%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 2%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 2%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 2%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 355px !important;
    }
}


@media only screen and (min-width: 2100px) and (max-width:2200px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 325px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 545px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -168px;
        right: -116px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 540px;
        height: 780px;
    }

    .swiper-new {
        height: 361px !important;
        width: 190px !important;
    }
    .swiper-button-next img {
        width: 57px;
    }

    .swiper-button-position{
        right: 23px;
        top: 286px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 20px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 18px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 262px;
        height: 419px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 101px;
        right: 31px;
        text-align: center;
        display: inline-block;

    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 2%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 2%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 2%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 2%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 386px !important;
    }
}


@media only screen and (min-width: 2200px) and (max-width:2300px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 16.5% !important;
    }

    .MobileScreenNew {
        max-width: 340px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 560px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -168px;
        right: -123px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 569px;
        height: 793px;
    }

    .swiper-new {
        height: 382px !important;
        width: 205px !important;
    }
    .swiper-button-next img {
        width: 57px;
    }

    .swiper-button-position{
        right: 23px;
        top: 286px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 22px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 18px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 275px;
        height: 445px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 99px;
        right: 33px;
        text-align: center;
        display: inline-block;
    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 2%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 2%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 2%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 2%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 386px !important;
    }
}


@media only screen and (min-width: 2300px) and (max-width:2400px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 356px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 600px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -176px;
        right: -130px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 595px;
        height: 840px;
    }

    .swiper-new {
        height: 410px !important;
        width: 225px !important;
    }
    .swiper-button-next img {
        width: 67px;
    }

    .swiper-button-position{
        right: 29px;
        top: 309px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 24px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 20px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 305px;
        height: 478px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 96px;
        right: 26px;
        text-align: center;
        display: inline-block;
    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 2%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 2%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 2%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 2%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 400px !important;
    }
}

@media only screen and (min-width: 2300px) and (max-width:3000px) {
    .mobileScreeMargin {
        margin-top: 15% !important;
        margin-left: 15% !important;
    }

    .MobileScreenNew {
        max-width: 356px !important;
        margin: auto;
        position: relative;
    }

    .inner_bulb_img {
        height: 600px !important;
    }

    .PointBlance3 {
        position: absolute;
        bottom: -176px;
        right: -130px;
        text-align: center;
        display: inline-block;
    }

    .phoneHand {
        width: 595px;
        height: 840px;
    }

    .swiper-new {
        height: 410px !important;
        width: 225px !important;
    }
    .swiper-button-next img {
        width: 67px;
    }

    .swiper-button-position{
        right: 29px;
        top: 309px;
    }

    .InnerContanetNew {
        background: #ccc;
        border-bottom: 2px solid #000;
        width: 100%;
        padding: 13px 8px 13px 8px;
        background-image: linear-gradient(to right, #93A5BC, #FFFFFF);
    }

    .InnerContanetNew h5 {
        font-size: 24px!important;
        font-weight: 600;
        color: #000;
        font-family: sans-serif;
    }
 
    .InnerContanetNew h6 {
        font-size: 20px!important;
        font-family: sans-serif;
        color: #2525b1;
    }

    .outer_img {
        width: 305px;
        height: 478px;
    }

    .PointBlanceNew2 {
        position: absolute;
        bottom: 96px;
        right: 26px;
        text-align: center;
        display: inline-block;
    }

    .td1 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 50px !important;
        padding-top: 2%;
    }

    .td2 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 70px !important;
        padding-top: 2%;
    }

    .td3 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 100px !important;
        padding-top: 2%;
    }

    .td4 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        max-width: 110px !important;
        padding-top: 2%;
    }

    .table_div {
        margin-top: 3%;
        background: #c6cbcfb3;
        margin-left: 30px;
        color: #000000;
        box-shadow: 6px 0px 24px 0px #000000;
        height: 400px !important;
    }
}



.inner_bulb_img {
    height: 410px;
}

.td1 {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 50px !important;
    // padding-top: 2%;
}

.td2 {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 70px !important;
    // padding-top: 2%;
}

.td3 {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100px !important;
    // padding-top: 2%;
}

.td4 {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 110px !important;
    // padding-top: 2%;
}

.table_div {
    margin-top: 3%;
    background: #c6cbcfb3;
    margin-left: 30px;
    color: #000000;
    box-shadow: 6px 0px 24px 0px #000000;
    height: 225px;
}

.pointBalanceNew_div{
    font-size: 16px;
}
.pointBalanceNew_txt{
    font-family: 'Myriad Pro';
    color: #002D62;
    font-size: 20px;
    font-weight: 9
}